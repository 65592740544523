import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faShoppingCart,
    faFileContract,
    faTruck,
    faWrench,
    faEnvelope,
    faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';

// import { ConstLink } from '../ConstLink';

import image from '../Images/container.png';
import ShippingContainer from '../Components/Container';
import container10ft from '../Images/TSC/10ft.jpeg';
import container40ft from '../Images/TSC/40ft.jpg';
import container40ft_open from '../Images/TSC/40ft_open.jpg';
import container40ft_side from '../Images/TSC/40ft_side_open.jpg';
import container_open from '../Images/TSC/container_open.jpeg';
import loader from '../Images/TSC/loader.jpeg';
import northwest1 from '../Images/TSC/northwest1.jpeg';
import northwest2 from '../Images/TSC/northwest2.jpeg';
import northwest3 from '../Images/TSC/northwest3.jpeg';
import northwest_truck from '../Images/TSC/northwest_truck.jpeg';
import picture1 from '../Images/TSC/Picture1.png';
import picture2 from '../Images/TSC/Picture2.jpg';
import picture3 from '../Images/TSC/Picture3.png';
import picture4 from '../Images/TSC/Picture4.png';
import picture5 from '../Images/TSC/Picture5.jpg';
import picture6 from '../Images/TSC/Picture6.png';
import picture7 from '../Images/TSC/Picture7.png';
import picture8 from '../Images/TSC/Picture8.png';
import picture9 from '../Images/TSC/Picture9.png';
import picture10 from '../Images/TSC/Picture10.png';
import picture11 from '../Images/TSC/Picture11.png';
import picture12 from '../Images/TSC/Picture12.png';
import logo from "../Images/container_logo.png";




export default function Home() {
    const navigate = useNavigate();
    // const { setQuickMenu, isLoggedIn } = useContext(ConstLink);

    // if (!isLoggedIn) {
    //     navigate('/login');
    // }

    return (
        <div className="container-home"
            style={{
                // height: '100vh',
                // width: '100%',
                // padding: '2% 15%',
                // backgroundPosition: 'center',
                // backgroundSize: 'cover',
                // backgroundRepeat: 'no-repeat',
                // position: 'relative',
            }}
            >
            {/*<ShippingContainer />   <============     Background Image*/}


            <div style={{ textAlign: 'center', padding: 50 }} id="blurbSection">

                <p className={'blurb'}
                    // style={{
                    //     textAlign: 'center',
                    //     fontSize: '36px',
                    //     color: '#555',
                    //     margin: '20px',
                    // }}
                >
                    Tamworth Shipping Containers | An exciting new division of Northwest Express Removals and Home delivery. We are your locally owned and Trusted family business operating 18 years. We have expanded to offer a range of new and used shipping containers of all shapes and sizes for sale and for hire.
                </p>

            </div>

            <div id="salesSection" className={'custom-section'}>
                <h1 style={{ marginBottom: '20px' }}>Shipping Container Sales & Hire</h1>
                <div className="container-titles">
                    <span id="new" className="container-title" style={{ margin: '0 5px' }}>New</span> |
                    <span id="used" className="container-title" style={{ margin: '0 5px' }}>Used</span> |
                    <span id="refurbished" className="container-title" style={{ margin: '0 5px' }}>Refurbished</span>
                </div>
                <div className="container-images">
                    <img src={container10ft} alt="New Container" className="container-image"/>
                    <img src={container40ft} alt="New Container" className="container-image"/>
                </div>
                <div className="container-images">
                    <img src={container40ft_open} alt="New Container" className="container-image"/>
                    <img src={container_open} alt="New Container" className="container-image"/>
                </div>
            </div>

{/*==== SHIPPING CONTAINERS ====*/}

            <div id="containerSection" className={'custom-section'}>
                <h1 style={{ marginBottom: '20px' }}>Shipping Containers</h1>
                <p style={{ maxWidth: '600px', margin: '0 auto' }}>
                    We carry a range of stock, but if we don’t have exactly what you are looking for, we will find it for you.
                </p>

                <ul style={{ listStyleType: 'none', maxWidth: '600px', margin: '20px auto', textAlign: 'center', paddingLeft: '0' }}>
                    <li>10ft General Purpose Shipping Containers</li>
                    <li>20ft General Purpose Shipping Containers</li>
                    <li>20ft High Cube Shipping Containers</li>
                    <li>40ft General Purpose Shipping Containers</li>
                    <li>40ft High Cube Shipping Containers</li>
                </ul>

                {/* Assuming you want to show images when the list items are clicked */}
                <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginTop: '20px' }}>
                    <img src={loader} alt="loader" className="single-image" style={{maxWidth: '80%'}}/>
                </div>
            </div>


{/*==== CONTAINER ACCESSORIES ====*/}


            <div id="modificationsSection" className={'custom-section'}>

                <h1 style={{ marginBottom: '20px'}}>Modifications & Accessories</h1>
                <p style={{ maxWidth: '600px', margin: '0 auto' }}>
                    Enhance your Shipping Container with Tamworth Shipping Containers. We can paint your shipping container to match your existing residence or business in any colour you choose, using only high-quality weatherproof products. We can also organise sign writing to advertise your business prior to delivery. Check out the range of products we can supply below.
                </p>

                <div style={{ marginTop: '20px', paddingTop: 50 }} >
                    <h2 style={{ color: '#333' }}>Security</h2>
                    <p style={{ maxWidth: '600px', margin: '0 auto' }}>
                        Fit your Shipping Container with the best anti-theft options available.
                    </p>
                    <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginTop: '20px' }}>
                        <img src={picture5} alt="lock" style={{ width: '30%', margin: '10px' }}/>
                        <img src={picture2} alt="lock" style={{ width: '30%', margin: '10px' }}/>
                        <img src={picture1} alt="lock" style={{ width: '30%', margin: '10px' }}/>

                    </div>
                </div>


                <div style={{ marginTop: '20px', paddingTop: 50 }}>
                    <h2 style={{ color: '#333' }}>Doors & Windows</h2>
                    <p style={{ maxWidth: '600px', margin: '0 auto' }}>
                        Fit your Shipping Container with a Utility Door for easy access or window for natural light and fresh air.
                    </p>
                    {/*<p style={{ textAlign: 'left', maxWidth: '600px', margin: '0 auto' }}>*/}
                    {/*    Fit your Shipping Container with a window for natural light and fresh air.*/}
                    {/*</p>*/}
                    <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginTop: '20px' }}>
                        <img src={picture4} alt="" className="single-image"/>
                    </div>
                </div>

                <div style={{ marginTop: '20px', paddingTop: 50 }} >
                    <h2 style={{ color: '#333' }}>Ventilation</h2>
                    <p style={{ maxWidth: '600px', margin: '0 auto' }}>
                        Fit your Shipping Container with a few more vents and keep cool during summer.
                    </p>
                    {/*<ul style={{ listStyleType: 'none', padding: '0', maxWidth: '600px', margin: '20px auto' }}>*/}
                    {/*    <li style={{ marginTop: '10px' }}>Whirly Birds</li>*/}
                    {/*    <li style={{ marginTop: '10px' }}>Sky light</li>*/}
                    {/*</ul>*/}

                    <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginTop: '20px' }}>
                        <img src={picture6} alt="" style={{ width: '30%', maxWidth: 150, maxHeight: 300, height: 'auto', margin: '10px' }}/>
                        <img src={picture7} alt="" style={{ width: '30%', maxWidth: 300, maxHeight: 300, height: 'auto', margin: '10px' }}/>
                        <img src={picture8} alt="" style={{ width: '30%', maxWidth: 300, maxHeight: 300, height: 'auto', margin: '10px' }}/>

                    </div>
                </div>

                <div style={{ marginTop: '20px', paddingTop: 50  }} className="modification-category">
                    <h2 style={{ color: '#333' }}>Whirly Birds</h2>
                    {/*<p style={{ textAlign: 'left', maxWidth: '600px', margin: '0 auto' }}>*/}
                    {/*    Fit your Shipping Container with a Utility Door for easy access.*/}
                    {/*</p>*/}
                    {/*<p style={{ textAlign: 'left', maxWidth: '600px', margin: '0 auto' }}>*/}
                    {/*    Fit your Shipping Container with a window for natural light and fresh air.*/}
                    {/*</p>*/}
                    <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginTop: '20px' }}>
                        <img src={picture9} alt="" style={{ width: '60%', maxWidth: 500, margin: '10px' }}/>
                    </div>
                </div>


                <div style={{ marginTop: '20px', paddingTop: 50 }} className="modification-category">
                    <h2 style={{ color: '#333' }}>Sky Lights</h2>
                    {/*<p style={{ textAlign: 'left', maxWidth: '600px', margin: '0 auto' }}>*/}
                    {/*    Fit your Shipping Container with a Utility Door for easy access.*/}
                    {/*</p>*/}
                    {/*<p style={{ textAlign: 'left', maxWidth: '600px', margin: '0 auto' }}>*/}
                    {/*    Fit your Shipping Container with a window for natural light and fresh air.*/}
                    {/*</p>*/}
                    <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginTop: '20px' }}>
                        <img src={picture10} alt="" style={{ width: '75%', maxWidth: 500, margin: '10px' }}/>
                    </div>
                </div>

                {/* Additional content and categories can be added here */}


                <div style={{ marginTop: '20px', paddingTop: 50}} className="modification-category">
                    <h2 style={{ color: '#333' }}>Transportation</h2>
                    <p style={{ maxWidth: '600px', margin: '0 auto' }}>
                        We offer door-to-door services with the use of a tilt tray and side-loading trucks for both 20ft Containers and 40ft Containers. The deliveries are contracted to local businesses. We refuse to profit from delivery. We are happy to put you in touch with the carrier or book it for you
                    </p>
                    {/*<p style={{ textAlign: 'left', maxWidth: '600px', margin: '0 auto' }}>*/}
                    {/*    Fit your Shipping Container with a window for natural light and fresh air.*/}
                    {/*</p>*/}
                    <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginTop: '20px' }}>
                        <img src={picture12} alt="" style={{ width: '100%', maxWidth: 700, margin: '10px' }}/>
                    </div>
                </div>

            </div>

            <div id="containerHireSection" className={'custom-section'}>
                <h1 style={{ marginBottom: '20px' }}>Shipping Container Hire</h1>
                <ul style={{ listStyleType: 'disc', maxWidth: '600px', margin: '20px auto', textAlign: 'left', paddingLeft: '20px' }}>
                    <li>
                        Decide on your size, type & the quality of container required.
                    </li>
                    <li>
                        Call 0402 272 447, <a href="mailto:benny@tamworthshippingcontainers.com" style={{ color: 'blue', fontWeight: 'bold' }}>email </a>
                         us , or drop into our yard to work out delivery time & day.
                    </li>
                    <li>
                        Fill in our rental agreement - <a href="#" onClick={() => { /* handle download */ }} style={{ color: 'blue', fontWeight: 'bold' }}>DOWNLOAD</a>
                    </li>
                    <li>The first invoice will ask for delivery & pick up cost as well as the first month's rental.</li>
                    <li>Ongoing hire will be deducted from your credit card before the due date.</li>
                    <li>On delivery let the driver know where you would like the container & if you would like to place any chocks under the container.</li>
                </ul>


                {/*<div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginTop: '20px' }}>*/}
                {/*    <img src={northwest1} alt="Shipping Container" style={{ width: '50%', margin: '10px' }}/>*/}
                {/*    <img src={northwest2} alt="Shipping Container" style={{ width: '50%', margin: '10px' }}/>*/}
                {/*    <img src={northwest3} alt="Shipping Container" style={{ width: '50%)', margin: '10px' }}/>*/}
                {/*    <img src={northwest_truck} alt="Shipping Container" style={{ width: '50%', margin: '10px' }}/>*/}
                {/*</div>*/}
            </div>


            <div id="mapSection" className={'custom-section'}>

                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d27352.6762997738!2d150.87623655796045!3d-31.023894244990068!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b0a9fd1e2d521a1%3A0x76e8b7eb31c0f047!2sTamworth%20Shipping%20Containers%20%7C%20A%20Division%20of%20Northwest%20Express%20Removals%20%26%20Home%20Delivery!5e0!3m2!1sen!2sau!4v1700358325178!5m2!1sen!2sau"
                    width="1000" height="600" allowFullScreen="" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"></iframe>
                {/*<iframe*/}
                {/*    src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d27342.64157556606!2d150.89102932663215!3d-31.05882631342676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1stamworth%20shipping%20containers!5e0!3m2!1sen!2sau!4v1700040385730!5m2!1sen!2sau"*/}
                {/*    width="1000" height="600" allowFullScreen="" loading="lazy"*/}
                {/*    referrerpolicy="no-referrer-when-downgrade"></iframe>*/}

            </div>


            <div id="googleSection  className={'custom-section'}">
                <div  style={{padding: 50}} className="elfsight-app-25492043-6498-4e94-8608-5fbae4f6948e"></div>
            </div>



            <div
                style={{
                    position: 'relative',
                    height: 150,
                    width: '100%',
                    // paddingTop: '35vh',
                }}
            ></div>
        </div>
    );
}