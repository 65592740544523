import React, { createContext, useState } from 'react';
const ConstLink = createContext();

const ConstProvider = ({ children }) => {

    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const [isActive, setIsActive] = useState(false);
    const [ QRCode, setQRCode] = useState(null);

    const [quickMenu, setQuickMenu] = useState([
        [null, null], [null, null], [null, null],
        [null, null], [null, null], [null, null]
    ]);

    const [ stepButtons, setStepButtons ] = useState([
        [null, null], [null, null], [null, null], [null, null], [null, null],
        [null, null], [null, null], [null, null], [null, null], [null, null],
        [null, null], [null, null], [null, null], [null, null], [null, null],
        [null, null], [null, null], [null, null], [null, null], [null, null]
    ]);


    const toggleMenu = () => {
        setIsActive(!isActive);
    };

    const closeHeaderMenu = () => {
        setIsActive(false)
    };

    const yourFunctionHere = () => {
        // Function Code...
    };

    return (
        <ConstLink.Provider value={{
            isActive,
            toggleMenu,
            closeHeaderMenu,
            isLoggedIn,
            setIsLoggedIn,
            stepButtons,
            setStepButtons,
            setQuickMenu,
            quickMenu,
            QRCode,
            setQRCode,

        }}>
            {children}
        </ConstLink.Provider>
    );
};

export { ConstProvider, ConstLink };






