import React, { useContext, useState, useRef, useEffect, Fragment } from 'react';
import { useNavigate, Link } from "react-router-dom";
import {ConstLink } from '../ConstLink';
import logo from '../Images/logo_dark.png';
import banner from '../Images/Radiation.ico';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faPhone } from '@fortawesome/free-solid-svg-icons';
import {FaDiscord} from "react-icons/fa";


// function importAll(r) {
//     let images = {};
//     r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
//     return images;
// }
//
// const images = importAll(require.context('../Images/TSC', false, /\.(png|jpe?g|svg|ico)$/));

const Header = () => {

    const navigate = useNavigate();
    const { closeHeaderMenu, toggleMenu, isActive, isLoggedIn } = useContext(ConstLink);
    const menuRef = useRef(null);
    const [username, setUsername] = useState('GUEST');

    useEffect(() => {

        // Run the colorCorrection function only when the viewport size is greater than 992 pixels
        if (window.innerWidth > 992) {

        }

        // Add an event listener to handle changes in viewport size
        const handleResize = () => {
            if (window.innerWidth > 992) {

            }
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleLogout = () => {
        // Handle logout logic here
    };

    const toggleHeaderMenu = () => {
        // toggleMenu();
        // window.scrollTo(0, document.body.scrollHeight);
    };

    const navigateTo = (address) => {
        if (window.innerWidth < 900) {
            closeHeaderMenu(); // Close the navigation menu by calling the toggleMenu function
        }
        navigate(address);
    };

    const scrollToBottom = () => {
        window.scrollTo(0, document.body.scrollHeight);
    };

    const toggleContactMenu = () => {
        const contactMenu = document.getElementById('contact-menu');
        const headerContainer = document.getElementById('headerContainer');

        if (contactMenu.style.display === 'block') {
            contactMenu.style.display = 'none';
            // contactMenu.style.textAlign = 'center';
            headerContainer.style.height = '100px';

        } else {
            contactMenu.style.display = 'block';
            headerContainer.style.height = '210px';
            // contactMenu.style.textAlign = 'center';
            // contactMenu.style.paddingTop = '75px';
        }
    };


    return (
        <div className="header-overflow">
            <nav>
                <div className="header-container" id="headerContainer">

                    <div className="navbar-container" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', color: '#ffffff' }}>

                        {/* Top section with logo, navigation, and menu */}
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            {/* Logo banner */}
                            <a href="/" className="logo-banner">
                                <img src={logo} alt="Your Logo" className="header-logo" />
                            </a>

                            {/* Hamburger Button and Contact Button */}
                            <div className="menu-container">
                                <a className="headerButton" onClick={toggleContactMenu}><FontAwesomeIcon icon={faBars} style={{color: "#ffffff"}}/></a>
                                {/*<a className="headerButton" onClick={() => navigateTo('/contact')}>CONTACT US</a>*/}
                            </div>

                        </div>


                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '0 20px 20px 0', height: '100%' }}>
                            {/* Placeholder for top content, if any */}
                            <div style={{ flex: 1 }}> {/* This div takes up the space, pushing the content below to the bottom */}
                                {/* Any additional content for the top part can go here */}
                            </div>

                            {/* Bottom Content */}
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>

                                {/*<div id={'sub-logo'} style={{width: '50%'}}>*/}
                                {/*    A Division of Northwest Express | Removals & Home Delivery*/}
                                {/*</div>*/}

                                <div id="sub-logo" className="subLogo" >
                                    A Division of Northwest Express | Removals & Home Delivery
                                </div>



                                {/* Contact Links */}
                                <div id="contact-menu">
                                    {/* Phone Number with Icon */}
                                    <div id="phone" style={{marginBottom: '10px', textAlign: 'center' }}>
                                        <a href="tel:0402272447" style={{ fontSize: 25, color: '#ffffff', textDecoration: 'none' }}>
                                            <FontAwesomeIcon icon={faPhone} style={{ marginRight: '5px' }}/>
                                            0402 272 447
                                        </a>
                                    </div>

                                    {/* Quick Enquiry Link */}
                                    <div style={{ textAlign: 'center' }}>
                                        <a href="https://form.jotform.com/233218613426048" style={{ "padding-left": 20 ,fontSize: 25, color: '#ffffff', textDecoration: 'none' }}>Quick Enquiry</a> {/* Replace 'your-jotform-link' with actual link */}
                                    </div>
                                </div>

                            </div>
                        </div>



                    </div>


                </div>
            </nav>
        </div>

    );
};


export default Header;
