import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './Styling/Styling.css';
import './Styling/Header.css';
import './Styling/Footer.css';
import './Styling/Social.css';
import { BrowserRouter as Router, Routes ,Route } from "react-router-dom";
import { ConstProvider } from 'ConstLink';

// Pages
import Login from "Pages/Login";
import Register from "Pages/Register";
import Home from "Pages/Home";
import Template from "Pages/Template";
import Test from "Pages/Test";

// Components
import Header from "./Components/Header";
import Footer from "./Components/Footer";
function App() {
  return (

      <Router>
        <div className="App">

          <ConstProvider>

            <header className="App-header">
              <div className="header-overflow"><Header /></div>
            </header>

            <Routes>

              <Route path="/" element={<Home/>} />
              {/*<Route path="/Login" element={<Login/>} />*/}
              {/*<Route path="/Register" element={<Register/>} />*/}
              {/*<Route path="/Template" element={<Template/>} />*/}
              {/*<Route path="/Test" element={<Test/>} />*/}

            </Routes>

            <footer>
              <Footer />
            </footer>
          </ConstProvider>
        </div>
      </Router>

  );
}

export default App;
