import React, { useContext, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { FaFacebook,
    FaInstagramSquare,
    FaEnvelope,
    FaPhone,
    FaGoogle,
    FaDiscord,
    FaYoutube,
    FaTwitter,
    FaLinkedin
} from 'react-icons/fa';

import { IoMdSchool } from 'react-icons/io';
import '../Styling/Footer.css';
import { ConstLink } from '../ConstLink';

function Footer() {

    const navigate = useNavigate();

    const {
        isLoggedIn,
        closeHeaderMenu,
        QRCode,
    } = useContext(ConstLink);

    // window.onload = function() {
    //     const menuButton = document.getElementById("open-menu");
    //     menuButton.click();
    // };

    function toggleFooterMenu() {
        const menuContainer = document.querySelector('.menu-container');
        const pageContent = document.querySelector('.page-content');

        // Check if the menu is currently open
        const isOpen = menuContainer.classList.contains('open');
        // toggleHeaderMenu();

        if (isOpen) {
            // Close the menu
            menuContainer.classList.remove('open');
            pageContent.classList.remove('open-menu');
        } else {
            // Open the menu
            // toggleMenu();
            closeHeaderMenu();
            menuContainer.classList.add('open');
            pageContent.classList.add('open-menu');
        }
    }

    const navigateTo = (address) => {
        console.log(address);
        toggleFooterMenu()
        navigate(address);
    };

    return (
        <div className="footer-container">
            <div className="footer">
                <div className="footer-icons">
                    <a href="https://www.facebook.com/profile.php?id=61550563472134&mibextid=LQQJ4d" target="_blank" rel="noreferrer"><FaFacebook className="footerIcon" /></a>
                    <a href="https://www.instagram.com/tamworthshippingcontainers?igshid=d2sycXIwbTVhczdz&utm_source=qr" target="_blank" rel="noreferrer"><FaInstagramSquare className="footerIcon" /></a>
                    <a href="https://www.google.com.au/search?sca_esv=583853767&cs=1&output=search&q=Tamworth+Shipping+Containers+%7C+A+Division+of+Northwest+Express+Removals+%26+Home+Delivery&ludocid=8568300511852294215&lsig=AB86z5XsG6D0Nd9iK8ZhBYfEgGye&kgs=e08fef4aad742bac&shndl=-1&source=sh/x/loc/act/m1/can/3" target="_blank" rel="noreferrer"><FaGoogle className="footerIcon" /></a>
                    <a href="mailto:benny@tamworthshippingcontainers.com" target="_blank" rel="noreferrer"><FaEnvelope className="footerIcon" /></a>
                    <a href="tel:0402272447" target="_blank" rel="noreferrer"><FaPhone className="footerIcon" style={{height: 40}} /></a>
                    {/*<a href="https://www.qut.edu.au" target="_blank" rel="noreferrer"><IoMdSchool className="footerIcon" /></a>*/}
                    {/*<a href="https://discord.gg/server_Number" target="_blank" rel="noreferrer"><FaDiscord className="footerIcon" /></a>*/}
                    {/*<a href="https://www.youtube.com/channel/channel_name" target="_blank" rel="noreferrer"><FaYoutube className="footerIcon" /></a>*/}
                    {/*<a href="https://twitter.com/Name_Goes_Here" target="_blank" rel="noreferrer"><FaTwitter className="footerIcon" /></a>*/}
                    {/*<a href="https://www.linkedin.com/in/Rest_of_link/" target="_blank" rel="noreferrer"><FaLinkedin className="footerIcon" /></a>*/}
                </div>
                <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
                    {/*<li><a*/}
                    {/*    // href="https://www.qrmezy.com/about">About Us</a> | <a*/}
                    {/*    href="https://www.qrmezy.com/contact">Quick Enquiry</a> | <a*/}
                    {/*    href="https://www.qrmezy.com/faq">FAQ</a> | <a*/}
                    {/*    href="https://www.qrmezy.com/privacy">Privacy Policy</a> | <a*/}
                    {/*    href="https://www.qrmezy.com/terms">Terms of Service</a>*/}
                    {/*</li>*/}
                </ul>
                {/*<div className="clear2"></div>*/}
                {/*<p className="copyright">*/}
                {/*    /!*Tamworth Shipping Containers &copy; | <a href="https://www.vsource.dev">V Source Development</a>*!/*/}
                {/*    Tamworth Shipping Containers &copy;*/}
                {/*</p>*/}
                <div className="menu-container">
                    {isLoggedIn && (
                        <ul id="menu">
                            <a className="menu-button icon-plus" id="open-menu" onClick={toggleFooterMenu} href="#menu" title="Show navigation"></a>
                            <a className="menu-button icon-minus" onClick={toggleFooterMenu} href="#0" title="Hide navigation"></a>
                        </ul>
                    )}
                </div>
            </div>
        </div>

    );
}

export default Footer;
